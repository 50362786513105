<template>
  <div class="about">
    <a href="/4" title="Accedi"><img :src="`${publicPath}img/intro/ubisell-logo.png`" ></a>
  </div>
</template>

<script>
export default {
  data() {
    return {
      publicPath: process.env.BASE_URL
    }
  }
}
</script>

<style scoped>
  .about {
    display: block;
    width: 100vw;
    height: 100vh;
    text-align: center;
    box-sizing: border-box;
    padding-top: 20vh;
  }
  .about a {
    width: 100%;
  }
</style>
